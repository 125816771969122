import process from "papaparse/.eslintrc";
import axios from "axios";
import {createStore} from "vuex";
import Request from "@/models/Request";
import DefaultGet from "@/components/requests/DefaultGet.vue";
import DefaultPost from "@/components/requests/DefaultPost.vue";

const store = createStore({
    state() {
        return {
            jsonSchema: localStorage.getItem('jsonSchema') === null ? {} : JSON.parse(localStorage.getItem('jsonSchema')),
            requests: localStorage.getItem('requests') === null ? new Map() : JSON.parse(localStorage.getItem('requests')),
            user: {
                isAuth: localStorage.getItem('isAuth') === null ? false : Boolean(localStorage.getItem('isAuth')),
                token: localStorage.getItem('token') === null ? false : localStorage.getItem('token'),
                email: localStorage.getItem('email') === null ? false : localStorage.getItem('email'),
            }
        }
    },
    getters: {
        /**
         *
         * @returns {Map<string, Request>}
         */
        getRequestsMap() {
            let requests = new Map()
            let requestsLocalStorage = store.state.requests;
            for (let i = 0; i < requestsLocalStorage.length; i++) {
                for (let j = 0; j < requestsLocalStorage[i][1].length; j++) {
                    let items = []
                    let tag = requestsLocalStorage[i][0]
                    if (store.getters.isIgnore(tag)) {
                        continue
                    }
                    if (typeof requests.get(tag) !== 'undefined') {
                        items = requests.get(tag)
                    }

                    items.push(requestsLocalStorage[i][1][j])
                    requests.set(tag, items)
                }
            }

            return requests;
        },
        getRoutes() {
            const keys = store.getters.getRequestsMap.keys();
            let requests = store.getters.getRequestsMap;
            let routes = []
            for (let i = 0; i < store.getters.getRequestsMap.size; i++) {
                let key = keys.next().value
                for (let j = 0; j < requests.get(key).length; j++) {
                    let component = store.getters.getComponent(requests.get(key)[j].method);
                    if (component === null) {
                        continue
                    }
                    routes.push({
                        path: requests.get(key)[j].url,
                        component: component,
                        props: () => ({
                            request: new Request(
                                requests.get(key)[j].path,
                                requests.get(key)[j].method,
                                requests.get(key)[j].label,
                                requests.get(key)[j].params,
                            )
                        })
                    })
                }
            }
            return routes;
        },
        getComponent: () => (method) => {
            if (method === 'get') {
                return DefaultGet;
            } else if (method === 'post') {
                return DefaultPost;
            }
            console.error('The method `' + method + '` has not yet been implemented')
            return null
        },
        isAuth: () => {
            return store.state.user.isAuth
        },
        isIgnore: () => (tag) => {
            return ['Auth'].includes(tag)
        },
        getToken: () => {
            return store.state.user.token
        },
        getEmail() {
            return store.state.user.email
        },
        initJsonSchema(state) {
            if (Object.keys(state.jsonSchema).length === 0) {
                return axios
                    .get(process.env.VUE_APP_API_URL + '/api/v3/default/json-schema')
                    .then((response) => {
                        localStorage.setItem('jsonSchema', JSON.stringify(response.data))
                        state.jsonSchema = response.data;
                        store.commit('initRequests');
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            return new Promise((resolve, reject) => {
                resolve('success');
                reject('error');
            })
        },
    },
    mutations: {
        initRequests(state) {
            if (state.requests.size !== 0) {
                return;
            }
            let paths = Object.keys(state.jsonSchema.paths)
            for (let i = 0; i < paths.length; i++) {
                let requests = Object.keys(state.jsonSchema.paths[paths[i]])
                for (let j = 0; j < requests.length; j++) {
                    let requestInfo = state.jsonSchema.paths[paths[i]][requests[j]]
                    for (let k = 0; k < requestInfo.tags.length; k++) {
                        let items = [];
                        let tag = requestInfo.tags[k]
                        if (store.getters.isIgnore(tag)) {
                            continue
                        }
                        let component = store.getters.getComponent(requests[j]);
                        if (component === null) {
                            console.error('Необходимо добавить компонент для отображения в меню и страницы')
                            continue
                        }
                        if (typeof state.requests.get(tag) !== 'undefined') {
                            items = state.requests.get(tag)
                        }
                        items.push(new Request(
                            paths[i],
                            requests[j],
                            requestInfo.summary,
                            requestInfo.parameters
                        ))
                        state.requests.set(tag, items)
                    }
                }
            }
            localStorage.setItem(
                'requests',
                JSON.stringify(state.requests, (key, value) => (value instanceof Map ? [...value] : value))
            )
        },
        updateApi(state) {
            localStorage.removeItem('jsonSchema');
            localStorage.removeItem('requests')
            state.jsonSchema = {}
            state.requests = new Map();
            store.getters.initJsonSchema.then(() => {
                window.location.href = window.location.origin;
            }).catch((err) => {
                console.log(err)
            })

        },
        setToken(state, token) {
            localStorage.setItem('token', token)
            state.user.token = token
        },
        setAuth(state, isAuth) {
            if (!isAuth) {
                localStorage.clear()
            }
            localStorage.setItem('isAuth', isAuth)
            state.user.isAuth = isAuth
        },
        setEmail(state, email) {
            localStorage.setItem('email', email)
            state.user.email = email
        },
        logout(state) {
            state.user.isAuth = false;
            state.user.token = '';
            state.user.email = '';
            state.jsonSchema = {}
            state.requests = new Map();
            localStorage.clear();
        },
        afterLogin(state, params) {
            store.commit('setEmail', params.email)
            store.commit('setToken', params.token)
            store.getters.initJsonSchema.then(() => {
                store.commit('setAuth', true);
            }).catch((err) => {
                store.commit('setAuth', false);
                console.log(err)
            })
        }
    }
})
export default store;
