<template>
  <div>
    <a href="https://pivottable.js.org/examples/local.html">Оригинальный пример</a>
    <p align="center" style="line-height: 1.5">
      Drop a CSV file on this page or
      <label id="filechooser">click here to choose one<input id="csv" type="file" style="display:none"></label>
      <br><br>
      <textarea placeholder="or type or paste CSV text here" style="width: 300px;" id="textarea"></textarea>
      <br>
      <em><small>note: the data never leaves your browser!</small></em>
    </p>
    <div id="output"></div>
  </div>
</template>

<script>

import jQuery from 'jquery'
import 'jquery-ui-dist/jquery-ui.min.js'
import 'pivottable/dist/pivot.min.js'
import 'pivottable/dist/pivot.min.css'
import Papa from 'papaparse'

export default {
  name: 'PivotTableV2',
  data() {
    return {
      params: []
    }
  },
  mounted() {
    let self = this
    jQuery(function () {
      var renderers = jQuery.extend(
          jQuery.pivotUtilities.renderers,
          jQuery.pivotUtilities.plotly_renderers,
          jQuery.pivotUtilities.d3_renderers,
          jQuery.pivotUtilities.export_renderers
      );

      var parseAndPivot = function (f) {
        jQuery("#output").html("<p align='center' style='color:grey;'>(processing...)</p>")
        Papa.parse(f, {
          skipEmptyLines: true,
          error: function (e) {
            alert(e)
          },
          complete: function (parsed) {
            self.params = parsed.data
            console.log(self.params, parsed.data)
            jQuery("#output").pivotUI(parsed.data, {
              rows: parsed.data[0],
              renderers: renderers
            }, true);
          }
        });
      };

      jQuery("#csv").bind("change", function (event) {
        parseAndPivot(event.target.files[0]);
      });

      jQuery("#textarea").bind("input change", function () {
        parseAndPivot(jQuery("#textarea").val());
      });

      var dragging = function (evt) {
        evt.stopPropagation();
        evt.preventDefault();
        evt.originalEvent.dataTransfer.dropEffect = 'copy';
        jQuery("body").removeClass("whiteborder").addClass("greyborder");
      };

      var endDrag = function (evt) {
        evt.stopPropagation();
        evt.preventDefault();
        evt.originalEvent.dataTransfer.dropEffect = 'copy';
        jQuery("body").removeClass("greyborder").addClass("whiteborder");
      };

      var dropped = function (evt) {
        evt.stopPropagation();
        evt.preventDefault();
        jQuery("body").removeClass("greyborder").addClass("whiteborder");
        parseAndPivot(evt.originalEvent.dataTransfer.files[0]);
      };

      jQuery("html")
          .on("dragover", dragging)
          .on("dragend", endDrag)
          .on("dragexit", endDrag)
          .on("dragleave", endDrag)
          .on("drop", dropped);
    });
  },
  methods: {}
}
</script>
