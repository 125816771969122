<template>
  <div class="container-fluid">
    <div class="row">
      <Loader v-if="isLoading"></Loader>
      <h1 class="col-12 text-center">{{ this.request.label }}</h1>

      <form class="col-12 w-auto text-start m-auto">
        <div class="alert alert-success" role="alert" v-if="isSuccess">
          Запись успешно добавленна id = {{ this.modelId }}
        </div>
        <template v-for="(field, index) in this.request.params" :key="index">
          <div class="col m-1">
            <label :for="field.name" class="form-label p-1">{{ field.description }}</label>
            <input v-if="isInteger(field)"
                   type="number"
                   class="form-control"
                   :name="field.name"
                   :id="field.name"
                   @input="event => field.value = event.target.value"
                   :value="field.value"
                   :placeholder="field.name"
                   :aria-label="field.name"
                   :required="field.required">
            <input v-else-if="isNumber(field)"
                   type="number"
                   class="form-control"
                   @input="event => field.value = event.target.value"
                   step="0.01"
                   :id="field.name"
                   :name="field.name"
                   :value="field.value"
                   :placeholder="field.name"
                   :aria-label="field.name">
            <input v-else-if="isString(field)"
                   type="text"
                   class="form-control"
                   @input="event => field.value = event.target.value"
                   :id="field.name"
                   :name="field.name"
                   :value="field.value"
                   :placeholder="field.name"
                   :aria-label="field.name">
            <VueDatePicker v-else-if="isDate(field)"
                           :id="field.name"
                           :name="field.name"
                           @input="event => field.value = format(event.target.value)"
                           v-model="field.value"
                           :format="format"
                           :placeholder="field.name"
                           :aria-label="field.name"
            ></VueDatePicker>
            <select v-else-if="isSelect(field)"
                    class="form-control"
                    :id="field.name"
                    :name="field.name"
            >
              <option v-for="(option,key) in getOptions(field)"
                      :key="key"
                      :value="option.key"
                      :selected="isSelected(option,field)"
              >
                {{ option.text }}
              </option>
            </select>
            <div :class="{
              'invalid-feedback': true,
              'd-block': isError(field)
            }">
              {{ getError(field) }}
            </div>
          </div>
        </template>
        <input type="submit" class="btn btn-info m-1" value="Сохранить" v-on:click="save">
      </form>
    </div>
  </div>
</template>
<script>
import Field from "@/mixins/Field.vue";
import axios from "axios";
import process from "papaparse/.eslintrc";
import Loader from "@/components/Loader.vue";
import store from "@/store/store";

export default {
  name: "DefaultPost",
  components: {Loader},
  mixins: [
    Field
  ],
  props: {
    request: {},
  },
  data() {
    return {
      isSuccess: false,
      isLoading: false,
      errors: [],
      modelId: null
    }
  },
  methods: {
    save(e) {
      this.isLoading = true;
      this.errors = []
      e.preventDefault()
      let url = new URL(process.env.VUE_APP_API_URL + this.request.path);
      url.searchParams.set('access-token', store.getters.getToken)
      const data = new URLSearchParams();
      for (let i = 0; i < this.request.params.length; i++) {
        if (this.request.params[i].value === undefined) {
          data.append(this.request.params[i].name, '');
        } else if (this.isDate(this.request.params[i])) {
          data.append(this.request.params[i].name, this.format(this.request.params[i].value));
        } else {
          data.append(this.request.params[i].name, this.request.params[i].value);
        }
      }
      axios.post(url.href, data, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }).then(response => {
        if (response.data.id) {
          this.isSuccess = true
          this.modelId = response.data.id
        } else if (response.data.errors) {
          this.errors = response.data.errors
        }
        console.log('Response:', response.data);
      }).catch(error => {
        console.error('Error:', error);
      }).finally(() => {
        this.isLoading = false
      })
    },
    isSelected(option, field) {
      return option.key === field.value
    },
    isError(field) {
      return this.errors[field.name] !== undefined
    },
    getError(field) {
      if (!this.isError(field)) {
        return ''
      }

      return this.errors[field.name].toString()
    }
  },
}
</script>
