class Request {
    path;
    method;
    label;
    url;
    params;

    constructor(path, method, label, params) {
        this.path = path;
        this.method = method
        this.label = label
        this.url = "/" + (path.replace('/api/v3/', '').replaceAll('/', '-')) + "-" + method;
        this.params = params;
    }
}

export default Request;
