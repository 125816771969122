<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-auto m-auto">
          <multiselect
              v-model="company"
              :options="companies"
              :searchable="true"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Выбирете компанию"
              label="short_name"
              track-by="id"
              @update:modelValue="onSelect"
              class="w-350"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div v-if="successMessage" class="alert alert-success w-auto" role="alert">
      <div v-html="this.successMessage"></div>
      <button type="button" class="btn-close" aria-label="Close" @click="clearSuccessMessage"></button>
    </div>
    <div v-if="errorMessage" class="alert alert-danger w-auto" role="alert">
      <div v-html="this.errorMessage"></div>
      <button type="button" class="btn-close" aria-label="Close" @click="clearErrorMessage"></button>
    </div>
    <div v-if="isConfigLoad">
      <div class="btn-group">
        <div class="btn btn-primary" v-on:click="addRow">Добавить строку</div>
        <div class="btn btn-primary" @click="save">Создать</div>
      </div>
      <ag-grid-vue
          class="ag-theme-quartz w-100"
          :columnDefs="columnDefs"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          rowSelection="single"
          :animateRows="true"
          domLayout='autoHeight'
          :autoSizeStrategy="{
            type: 'fitCellContents'
          }"
      ></ag-grid-vue>
    </div>
  </div>
</template>

<script>

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "vue-multiselect/dist/vue-multiselect.css";
import {AgGridVue} from "ag-grid-vue3";
import Multiselect from 'vue-multiselect'
import process from "papaparse/.eslintrc";
import store from "@/store/store";
import axios from "axios";

export default {
  components: {
    AgGridVue,
    Multiselect,
  },
  data() {
    return {
      gridApi: null,
      columnDefs: [],
      rowData: [],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      companies: [],
      company: null,
      isConfigLoad: false,
      configItems: [],
      errorMessage: '',
      successMessage: '',
    };
  },
  mounted() {
    this.loadCompanies();
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    onSelect(company) {
      if (company) {
        this.loadConfig(company.id);
      }
    },
    addRow() {
      let names = {}
      this.configItems.forEach(item => {
        names[item.name] = null;
      })
      this.gridApi.applyTransaction({add: [names]});
    },
    clearErrorMessage() {
      this.errorMessage = ''
    },
    clearSuccessMessage() {
      this.successMessage = ''
    },
    save() {
      let self = this
      let jobs = [];
      this.clearErrorMessage();
      this.clearSuccessMessage();
      this.gridApi.forEachNode(node => {
        let item = node.data
        for (var key in item) {
          if (self.isRequired(key) && (item[key] === '' || item[key] === null)) {
            self.errorMessage = 'Необходимо заполнить все обязательные поля (они подсвечены красным)!'
            return;
          }
          if (self.getType(key) === 'date') {
            item[key] = self.getDateFormat({value: item[key]})
          }
        }
        jobs.push(item)
      });
      for (let i = 0; i < jobs.length; i++) {
        if (!self.isValidDateInterval(jobs[i].start_datetime, jobs[i].end_datetime)) {
          self.errorMessage = '`Дата начала` не может быть больше `Даты завершения`'
          return;
        }
        if (self.isRequiredPriceListId(jobs[i]) && (jobs[i].price_list_id === '' || jobs[i].price_list_id === null)) {
          self.errorMessage = '`Приложение к договору` обязательно для `Вид договора` такие как:<br>';
          self.errorMessage += '`Договор с переменной стоимостью услуг c подписанием через СМС`<br>';
          self.errorMessage += '`Договор с переменной стоимостью услуг с автоподписанием`';
          return;
        }
      }
      if (jobs.length === 0) {
        return;
      }
      const data = new URLSearchParams();
      data.append('companyId', this.company.id)
      data.append('jobs', JSON.stringify(jobs))
      let url = new URL(process.env.VUE_APP_API_URL + '/api/v3/job/create');
      url.searchParams.set('access-token', store.getters.getToken)

      axios.post(url.href, data).then(function () {
        self.successMessage = 'Задания добавленны в очередь для создания!'
        self.clearTable();
      }).catch(function (error) {
        self.errorMessage = 'Не удалось сохранить! Обратитесь к программистам!'
        console.log(error.message);
      });
    },
    clearTable() {
      this.gridApi.applyTransaction({remove: this.rowData});
      this.rowData = [];
    },
    isValidDateInterval(start, end) {
      if (end === '' || end === null) {
        return true;
      }
      const dateStart = new Date(start)
      const dateEnd = new Date(end)
      if (dateStart.getTime() > dateEnd.getTime()) {
        return false;
      }
      return true;
    },
    isRequiredPriceListId(job) {
      const JOB_CONTRACT_TYPE_FLEXIBLE = 2;
      const JOB_CONTRACT_TYPE_FLEXIBLE_AUTO = 3;

      return [JOB_CONTRACT_TYPE_FLEXIBLE, JOB_CONTRACT_TYPE_FLEXIBLE_AUTO].indexOf(job.contract_type) !== -1;
    },
    loadCompanies() {
      let self = this;
      let url = new URL(process.env.VUE_APP_API_URL + '/api/v3/job/companies');
      url.searchParams.set('access-token', store.getters.getToken)

      axios.get(url.href).then(function (response) {
        response.data.items.forEach(function (element) {
          self.companies.push({id: element.id, short_name: element.short_name})
        })
      }).catch(function (error) {
        console.log(error.message);
      });
    },
    loadConfig(companyId) {
      let self = this;
      let url = new URL(process.env.VUE_APP_API_URL + '/api/v3/job/config');
      url.searchParams.set('companyId', companyId)
      url.searchParams.set('access-token', store.getters.getToken)

      axios.get(url.href).then(function (response) {
        self.configItems = response.data
        self.columnDefs.push({
          headerName: "#",
          valueGetter: (params) => {
            return params.node.rowIndex + 1;
          },
          width: 70,
        })
        response.data.forEach(function (element) {
          self.setColumnDef(element)
        })
        self.isConfigLoad = true;
      }).catch(function (error) {
        console.log(error.message);
      });
    },
    setColumnDef(element) {
      let self = this
      let item = {
        headerName: element.label,
        field: element.name,
        editable: true,
      }
      if (element.isRequired) {
        item.cellStyle = (params) => {
          if (params.value === '' || params.value === null) {
            return {backgroundColor: 'red'};
          }
          return {backgroundColor: 'white'};
        };
      }

      if (element.type === 'text') {
        item.cellEditor = 'agTextCellEditor'
      } else if (element.type === 'int') {
        item.cellEditor = 'agNumberCellEditor'
      } else if (element.type === 'select') {
        item.cellEditorParams = {
          values: this.getSelectValues(item, element),
        }
        item.valueFormatter = (params) => {
          for (let i = 0; i < element.options.length; i++) {
            if (element.options[i].id == params.value) {
              return element.options[i].title
            }
          }
          return params.value
        }

      } else if (element.type === 'date') {
        item.cellEditor = 'agDateCellEditor'
        item.valueFormatter = (params) => {
          return self.getDateFormat(params);
        }
      } else {
        console.log("Error type: ", element.type);
      }
      this.columnDefs.push(item)
    },
    getSelectValues(item, element) {
      let values = [];
      item.cellEditor = 'agSelectCellEditor';
      let items = Array.from(element.options)
      for (let i = 0; i < items.length; i++) {
        values.push(items[i].id)
      }
      return values
    },
    getDateFormat(params) {
      if (params.value === '' || params.value === null) {
        return '';
      }
      const date = new Date(params.value)
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${date.getFullYear()}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
    },
    getType(element) {
      for (let i = 0; i < this.configItems.length; i++) {
        if (element === this.configItems[i].name) {
          return this.configItems[i].type
        }
      }
      return null
    },
    isRequired(element) {
      for (let i = 0; i < this.configItems.length; i++) {
        if (element === this.configItems[i].name) {
          return this.configItems[i].isRequired
        }
      }
      return null
    },
  },
};
</script>

<style>
.w-350 {
  width: 350px;
}

.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
