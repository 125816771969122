<template>
  <div id="search">
    <form method="get">
      <div class="row">
        <div class="container mt-3">
          <table class="table">
            <thead>
            <tr>
              <th scope="col" v-for="(field, index) in this.request.params" :key="index">
                {{ getFieldDescription(field) }}
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td v-for="(field, index) in this.request.params" :key="index">
                <input v-if="isInteger(field)"
                       type="number"
                       class="form-control"
                       :name="field.name"
                       :value="getFieldValue(field.name)"
                       :placeholder="field.name"
                       :aria-label="field.name">
                <input v-else-if="isString(field)"
                       type="text"
                       class="form-control"
                       :name="field.name"
                       :value="getFieldValue(field.name)"
                       :placeholder="field.name"
                       :aria-label="field.name">
                <VueDatePicker v-else-if="isDate(field)"
                               v-model="this.dates[index]"
                               :format="format"
                               :name="field.name"
                               :placeholder="field.name"
                               :aria-label="field.name"
                ></VueDatePicker>
                <select v-else-if="isSelect(field)"
                        class="form-control"
                        :name="field.name"
                >
                  <option v-for="(option,key) in getOptions(field)"
                          :key="key"
                          :value="option.key"
                          :selected="isSelected(option,field)"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </td>
              <td>
                <input type="submit" class="btn btn-info" value="search">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import Field from "@/mixins/Field";

export default {
  name: 'DefaultSearch',
  components: [
    VueDatePicker
  ],
  mixins:[
    Field
  ],
  props: {
    request: {}
  },
  data() {
    return {
      searchParams: (new URL(window.location.href)).searchParams,
      dates: [],
    }
  },
  watch: {
    request: {
      handler(newVal) {
        for (let i = 0; i < newVal.params.length; i++) {
          if (this.isDate(newVal.params[i])) {
            this.dates[i] = this.searchParams.get(newVal.params[i].name)
          }
        }
      },
      deep: true, // Опция deep для отслеживания изменений вложенных свойств
      immediate: true // Опция immediate для вызова обработчика немедленно при монтировании компонента
    }
  },
  methods: {
    getFieldValue(field) {
      return this.searchParams.get(field) == null ? '' : this.searchParams.get(field)
    },
    isSelected(option, field) {
      if (this.searchParams.get(field.name) === null || this.searchParams.get(field.name) === '') {
        return false;
      }

      return option.key == this.searchParams.get(field.name);
    },
  }
}
</script>
