<template>
  <div class="container-fluid">
    <div v-if="$store.getters.isAuth" class="d-flex vh-100">
      <!-- Левое меню -->
      <div class="bg-light border-end" id="sidebar-wrapper">
        <div class="sidebar-heading">CRM</div>
        <div class="list-group list-group">
          <div v-for="[tag,requests] in $store.state.requests" v-bind:key="tag">
            <p class="p-3 m-0 text-primary">{{ tag }}</p>
            <a v-for="request in requests"
               v-bind:key="request.path"
               :href="request.url"
               class="list-group-item list-group-item-action list-group-item-light p-3 text-secondary"
            >
              {{ request.label }}
            </a>
          </div>
          <!-- Дополнительные пункты меню -->
        </div>
      </div>

      <!-- Содержимое страницы -->
      <div class="w-100">
        <nav class="navbar navbar-expand navbar-light bg-light">
          <div class="container-fluid">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a href="/" class="nav-link">Home</a>
                </li>
                <li class="nav-item">
                  <a href="/pivot-table-v2" class="nav-link">PivotTable пакетная загрузка(demo)</a>
                </li>
                <li class="nav-item">
                  <a href="/import-job" class="nav-link">Импорт заданий</a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link" v-on:click="updateApi">Обновить API</a>
                </li>
              </ul>
              <ul class="navbar-nav mb-2 mb-lg-0 text-end">
                <li class="nav-item">
                  <a href="#" class="nav-link" v-on:click="logout">{{ store.getters.getEmail }}(Выйти)</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <router-view></router-view>
      </div>
    </div>
    <!-- отображаем тут компонент, для которого совпадает маршрут -->
    <div v-if="!$store.getters.isAuth" class="d-flex vh-100">
      <login-page></login-page>
    </div>
  </div>
</template>
<script>

import LoginPage from "@/pages/LoginPage.vue";
import store from "@/store/store";

export default {
  name: 'App',
  computed: {
    store() {
      return store
    }
  },
  components: {LoginPage},
  methods: {
    updateApi(e) {
      e.preventDefault();
      store.commit('updateApi')
    },
    logout() {
      store.commit('logout')
    }
  }
}
</script>
<style scoped>
#sidebar-wrapper {
  min-width: 250px;
  max-width: 250px;
}

.sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

.list-group-item-action {
  width: 100%;
  text-align: left;
}
</style>
