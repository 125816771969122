<script>
export default {
  name: 'FieldMixin',
  methods: {
    getText(description) {
      return description.replace(/[0-9/./,]/g, "").replace(' - ', '')
    },
    getFieldDescription(field) {
      let description = field.description
      if (field.description.includes('\n')) {
        return description.split('\n')[0];
      }
      return this.getText(description)
    },
    getOptions(field) {
      let options = [];
      let descriptions = field.description.split('\n');
      for (let i = 0; i <= field.enum.length; i++) {
        options[i] = {
          key: i === 0 ? '' : field.enum[i - 1],
          text: i === 0 ? field.name : this.getText(descriptions[i])
        }
      }
      return options
    },
    isInteger(field) {
      return field.type === 'integer'
    },
    isNumber(field) {
      return field.type === 'number'
    },
    isString(field) {
      return field.type === 'string' && field.enum === undefined && !this.isDate(field)
    },
    isDate(field) {
      return field.format === 'date'
    },
    isSelect(field) {
      return field.type === 'string' && field.enum !== undefined
    },
    format(date) {
      let day = date.getDate().toString();
      let month = (date.getMonth() + 1).toString();
      const year = date.getFullYear();
      day = day.length === 1 ? '0' + day : day;
      month = month.length === 1 ? '0' + month : month;
      return `${year}-${month}-${day}`;
    }
  },
}
</script>
