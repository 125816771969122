<template>
  <nav>
    <ul class="pagination">
      <li :class="{'page-item':true,'disabled':this.meta.currentPage === 1}">
        <a class="page-link" :href="this.getUrlPreviousPage()">Previous</a>
      </li>
      <li v-for="(page, index) in this.getRenderPage()"
          v-bind:key="index"
          :class="{'page-item':true,'active':page.isActive}">
        <a class="page-link" :href="page.href">{{ page.number }}</a>
      </li>
      <li :class="{'page-item':true,'disabled':this.meta.currentPage === this.meta.pageCount}">
        <a class="page-link" :href="this.getUrlNextPage()">Next</a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'PaginationCustom',
  props: {
    meta: {
      currentPage: 0,
      pageCount: 0,
      perPage: 0,
      totalCount: 0
    },
  },
  methods: {
    getMaxPage() {
      const maxPage = 5
      if (this.meta.pageCount <= maxPage) {
        return this.meta.pageCount;
      }
      return maxPage;//todo не более 5 возможно нужно будет заменить на что-то более гибкое
    },
    getRenderPage() {
      let halfMax = Math.floor(this.getMaxPage() / 2);
      let startPage = Math.max(1, this.meta.currentPage - halfMax);

      let endPage = Math.min(startPage + this.getMaxPage() - 1, this.meta.pageCount);

      if (endPage - startPage < this.getMaxPage() - 1) {
        startPage = Math.max(1, endPage - this.getMaxPage() + 1);
      }
      let pages = [];

      for (let i = startPage; i <= endPage; i++) {
        pages.push({
          number: i,
          href: this.getUrl(i),
          isActive: i === this.meta.currentPage
        });
      }
      return pages;
    },
    getUrlNextPage() {
      if (this.meta.currentPage < this.meta.pageCount) {
        return this.getUrl(this.meta.currentPage + 1)
      }
      return '#'
    },
    getUrlPreviousPage() {
      if (this.meta.currentPage > 1) {
        return this.getUrl(this.meta.currentPage - 1)
      }
      return '#'
    },
    getUrl(page) {
      let url = (new URL(window.location.href))
      url.searchParams.set('page', page)
      return url.search
    }
  }
}
</script>
