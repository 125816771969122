<template>
  <div class="m-2">
    <h3 class="text-center"> {{ this.request.label }}</h3>
    <div class="text-start mb-2">Всего: {{ this.getPageStart() }} - {{ this.getPageEnd() }} из
      {{ this.meta.totalCount }}
    </div>
    <default-search v-model:request="this.$props.request"></default-search>
    <div id="output" class="mt-2"></div>
    <div class="mt-2" v-if="this.isLoading && this.meta.totalCount">
      <pagination-custom v-model:meta="this.meta"></pagination-custom>
    </div>
  </div>
</template>
<script>

import jQuery from 'jquery'
import 'jquery-ui-dist/jquery-ui.min.js'
import 'pivottable/dist/pivot.min.js'
import 'pivottable/dist/pivot.min.css'
import axios from "axios";
import process from "papaparse/.eslintrc";
import PaginationCustom from "@/components/PaginationCustom.vue";
import DefaultSearch from "@/components/search/DefaultSearch.vue";
import store from "@/store/store";

export default {
  name: 'DefaultGet',
  components: {
    PaginationCustom,
    DefaultSearch
  },
  props: {
    request: {},
  },
  data() {
    return {
      searchFields: [],
      meta: {
        currentPage: 0,
        pageCount: 0,
        perPage: 0,
        totalCount: 0
      },
      isLoading: false
    }
  },
  mounted() {
    this.renderPivotTable();
  },
  methods: {
    renderPivotTable() {
      let self = this
      let items = []
      let url = new URL(process.env.VUE_APP_API_URL + this.request.path);
      url.searchParams.set('access-token', store.getters.getToken)
      let selfUrl = new URL(window.location.href)
      selfUrl.searchParams.forEach((value, key) => {
        if (value != '') {
          url.searchParams.set(key, value)
        }
      })
      axios.get(url.href).then(function (response) {
        items = response.data.items;
        self.meta = response.data._meta
        let rows = []
        if (items.length > 0) {
          rows = Object.keys(items[0])
        }
        jQuery("#output").pivotUI(
            items, {
              rows: rows
            }
        );
        self.searchFields = rows;
        self.isLoading = true
      }).catch(function (error) {
        console.log(error.message);
      });
    },
    getPageStart() {
      if (this.meta.totalCount === 0) {
        return 0;
      }
      return this.meta.currentPage * this.meta.perPage - this.meta.perPage + 1;
    },
    getPageEnd() {
      if (this.meta.currentPage * this.meta.perPage >= this.meta.totalCount) {
        return this.meta.totalCount;
      }
      return this.meta.currentPage * this.meta.perPage;
    },
  }
}
</script>
