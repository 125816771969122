import {createApp} from 'vue'
import App from './App.vue'
import jQuery from 'jquery'
import 'pivottable/dist/pivot.min.js'
import 'pivottable/dist/pivot.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap'
import './assets/css/default.css'
import router from "@/router";
import store from "@/store/store";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

app.config.globalProperties.$ = jQuery
app.component('VueDatePicker', VueDatePicker);
app.use(router).use(store).mount('#app')
