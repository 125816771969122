import {createRouter, createWebHistory} from "vue-router";
import PivotTableV2 from "@/components/PivotTableV2.vue";
import HomePage from "@/pages/HomePage.vue";
import store from "@/store/store";
import ImportJobPage from "@/pages/ImportJobPage.vue";

export default createRouter({
    history: createWebHistory(),
    routes: [
        {path: "/pivot-table-v2", component: PivotTableV2},
        {path: "/import-job", component: ImportJobPage},
        {path: "/", component: HomePage},
    ].concat(store.getters.getRoutes)
})
