<template>
  <div class="login-page col align-self-center m-auto text-center p-1">
    <Loader v-if="isLoading"></Loader>
    <h1>Login</h1>
    <form @submit.prevent="handleLogin">
      <div>
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required/>
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="password" required/>
        <div :class="{
              'invalid-feedback': true,
              'd-block': isError
            }">
          {{ errorMessage }}
        </div>
      </div>
      <button type="submit" class="btn btn-primary">Login</button>
    </form>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import store from "@/store/store";
import axios from "axios";
import process from "papaparse/.eslintrc";

export default {
  name: 'LoginPage',
  components: {Loader},
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      isError: false,
      errorMessage: ''
    };
  },
  methods: {
    handleLogin(e) {
      e.preventDefault()
      let self = this
      self.isLoading = true;
      self.isError = false;
      let url = new URL(process.env.VUE_APP_API_URL + '/api/v3/auth/login');
      let email = self.email
      axios.post(url.href, {
        email: self.email,
        password: self.password,
      }, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }).then(function (response) {
        if (response.data.token === undefined) {
          self.isError = true;
          self.errorMessage = 'Не верный логин или пароль'
        } else {
          store.commit('afterLogin', {
            token: response.data.token,
            email: email
          })
        }
        self.isLoading = false;
      }).catch((err) => {
        if (err.code === "ERR_NETWORK") {
          self.isError = true;
          self.errorMessage = 'Не удалось получить доступ к api'
        }
        self.isLoading = false;
      })
    }
  }
};
</script>

<style scoped>
.login-page {
  max-width: 300px;
}

.login-page form div {
  margin-bottom: 1em;
}

.login-page form label {
  display: block;
  margin-bottom: 0.5em;
}

.login-page form input {
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
}
</style>
